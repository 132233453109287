import React from 'react';
import { Button, Card, Col, Row, Typography } from 'antd';
import { SaveOutlined, EnvironmentOutlined } from '@ant-design/icons';

const { Title, Paragraph } = Typography;

const ContactPage = () => {
    const saveContact = () => {
        const vCard = `
BEGIN:VCARD
VERSION:3.0
FN:Nihal Gems (PVT) LTD
ORG:Nihal Gems (PVT) LTD
TEL:0777700149
EMAIL:hello@nihalgems.com
ADR:244, Colombo Road, Moragahayata, Ratnapura
URL:www.nihalgems.com
END:VCARD
    `;
        const blob = new Blob([vCard], { type: 'text/vcard' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'Nihal_Gems_Contact.vcf';
        link.click();
    };

    return (
        <div style={{ padding: '20px' }}>
            <Row gutter={16} justify="center">
                {/* Left Column - Contact Details */}
                <Col
                    xs={24}
                    sm={12}
                    md={18}
                    style={{
                        paddingRight: '10px',
                        display: 'flex',
                        justifyContent: 'center', // Center horizontally
                        alignItems: 'center',      // Center vertically
                    }}
                >
                    <Card
                        bordered={false}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',  // Center vertically inside the card
                            alignItems: 'center',      // Center horizontally inside the card
                            textAlign: 'center',       // Center text inside the card
                            width: '100%',
                        }}
                    >
                        <Title level={1}>Nihal Gems (PVT) LTD</Title>
                        <Paragraph style={{ fontSize: '1.6em' }}>
                            <strong>Website: </strong>
                            <a href="http://www.nihalgems.com" target="_blank" rel="noopener noreferrer">www.nihalgems.com</a>
                        </Paragraph>
                        <Paragraph style={{ fontSize: '1.6em' }}>
                            <strong>Phone: </strong>07777 00 149
                        </Paragraph>
                        <Paragraph style={{ fontSize: '1.6em' }}>
                            <strong>Email: </strong>
                            <a href="mailto:hello@nihalgems.com">hello@nihalgems.com</a>
                        </Paragraph>
                        <Button
                            type="primary"
                            icon={<SaveOutlined />}
                            size="large"
                            onClick={saveContact}
                            block
                        >
                            Add to Contacts
                        </Button>
                    </Card>
                </Col>

                {/* Right Column - Picture */}
                <Col
                    xs={24}
                    sm={12}
                    md={6}
                    style={{
                        display: 'flex',
                        justifyContent: 'center', // Center horizontally on mobile
                        alignItems: 'center',      // Center vertically on mobile
                        maxHeight: '400px',        // Limit the height of the image
                        overflow: 'hidden',        // Prevent overflow of image
                    }}
                >
                    <Card
                        cover={
                            <img
                                alt="Nihal Gems"
                                src="https://i.ibb.co/rkLkmHq/Nihal-Gems-PVT-LTD.png"
                                style={{
                                    objectFit: 'contain',     // Ensures the full image fits within the container without cropping
                                    maxHeight: '400px',       // Limits the image height to 400px
                                    width: '100%',            // Ensures the image takes full width of the container
                                }}
                            />
                        }
                        bordered={false}
                        hoverable
                    />
                </Col>
            </Row>

            {/* Google Map Section */}
            <Row gutter={16} style={{ marginTop: '20px' }} justify="center">
                <Col span={24}>
                    <Card
                        bordered={false}
                        bodyStyle={{ padding: '10px' }}
                        title={<><EnvironmentOutlined /> Location</>}
                    >
                        <div style={{ width: '100%', height: '400px' }}>
                            <iframe
                                title="Nihal Gems Location"
                                width="100%"
                                height="100%"
                                frameBorder="0"
                                scrolling="no"
                                marginHeight="0"
                                marginWidth="0"
                                src="https://www.google.com/maps/embed/v1/place?key=AIzaSyAp4njI0ITEEm5wktSZhpI2wao8eVPNoKg&q=nihal%20gems&zoom=18"
                                allowFullScreen
                            />
                        </div>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default ContactPage;
