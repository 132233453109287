import React, { useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Home from "./pages/Home";
import Customers from "./pages/Customers/Customers";
import Profile from "./pages/Profile";
import SignUp from "./pages/SignUp";
import Main from "./components/layout/Main";
import Login from "./pages/login/LoginPage";
import AddItemsForm from "./pages/Inventory/AddItemsForm/AddItemsForm";
import Rough from "./pages/Inventory/Rough/Rough";
import Lots from "./pages/Inventory/Lots/Lots";
import SortedLots from "./pages/Inventory/Sorted Lots/Sorted Lots";
import CP from "./pages/Inventory/CP/CP";
import AddTransactionForm from "./pages/Transaction/AddTransactionForm/AddTransactionForm";
import AddPaymentsForm from "./pages/Transaction/AddPaymentsForm/AddPaymentsForm";
import Expenses from "./pages/Transaction/Expenses/Expenses";
import HT from "./pages/Operations/TreatmentGroup/HT";
import HeatT from "./pages/Operations/HeatTreatment/HeatT";
import CutPolish from "./pages/Operations/CutPolish/CutPolish";
import ItemURL from "./pages/GlobalViewModels/ItemURL";
import CustomerURL from "./pages/GlobalViewModels/CustomerURL";
import Yasiru from "./pages/Profiles/Yasiru";
import ContactPage from "./pages/Profiles/General";
import Cookies from "js-cookie";

import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import Buying from "./pages/Transaction/Buying/Buying";
import Selling from "./pages/Transaction/Selling/Selling";
import CashBook from "./pages/Transaction/CashBook/CashBook";
import ElectricTreatment from "./pages/Operations/ElectricTreatment/ElectricTreatment";
import SortLots from "./pages/Operations/SortLots/SortLots";
import Invoice from "./pages/Transaction/Invoice/Invoice";
import Loan from "./pages/Transaction/Loans/Loan";
import HeatTreatmentOven from "./pages/IOT/HeatTreatmentOven";

function App() {
  let rememberedUser = Cookies.get("rememberedUser");
  const is_cookie_set = Boolean(rememberedUser);

  let ROLE1 = "USER";

  if (rememberedUser) {
    rememberedUser = JSON.parse(rememberedUser);
    const { ROLE } = rememberedUser;
    ROLE1 = ROLE;
  }

  //if (ROLE1 === "CP_USER" only allow access to dashboard and CP



  useEffect(() => {
    if (!is_cookie_set) {
      // Check if the current path is already '/'
      const currentPath = window.location.pathname;

      // Define an array of paths that should not redirect
      const allowedPaths = ['/'];

      // Check if the current path is in the allowed paths or has a dynamic :code part
      if (!allowedPaths.includes(currentPath) && !currentPath.match(/^\/(rough|lots|sorted-lots|c-p|customer|profile)\/[^/]+$/)) {
        // If not, redirect to '/'
        window.location.href = "/";
      }
    } else if (window.location.pathname === '/') {
      // Redirect to '/dashboard' if a valid cookie is set and the path is '/'
      window.location.href = "/dashboard";
    }
  }, [is_cookie_set]);

  if (ROLE1 === "CP_USER") {
    return (
        <div className="App">
          <Switch>
            <Route path="/" exact component={Login} />
            <Main>
              <Route exact path="/dashboard" component={Home} />
              <Route exact path="/profile" component={Profile} />
              <Redirect from="*" to="/dashboard" />
            </Main>
          </Switch>
        </div>
    );
  }




  return (
      <div className="App">
        <Switch>
          <Route path="/" exact component={Login} />
          <Route exact path="/profile/yasiru" component={Yasiru} />
          <Route exact path="/profile/general" component={ContactPage} />
          <Route exact path="/customer/:id" component={CustomerURL} />
          <Route exact path="/customer/:id" component={CustomerURL} />
          {!is_cookie_set && (
              <>
                <Route exact path="/sorted-lots/:code" component={ItemURL} />
                <Route exact path="/c-p/:code" component={ItemURL} />
                <Route exact path="/lots/:code" component={ItemURL} />
                <Route exact path="/rough/:code" component={ItemURL} />

              </>
          )}
          <Main>
            <Route exact path="/dashboard" component={Home} />
            <Route exact path="/sign-up" component={SignUp} />
            <Route exact path="/customers" component={Customers} />
            <Route exact path="/treatment-group" component={HT} />
            <Route exact path="/heat-treatment" component={HeatT} />
            <Route exact path="/elec-treatment" component={ElectricTreatment} />
            <Route exact path="/c-and-p" component={CutPolish} />
            <Route exact path="/sort-lots" component={SortLots} />
            <Route exact path="/add-items" component={AddItemsForm} />
            <Route exact path="/rough" component={Rough} />
            <Route exact path="/lots" component={Lots} />
            <Route exact path="/sorted-lots" component={SortedLots} />
            <Route exact path="/c-p" component={CP} />
            <Route exact path="/add-transation" component={AddTransactionForm} />
            <Route exact path="/add-payments" component={AddPaymentsForm} />
            <Route exact path="/invoice" component={Invoice} />
            <Route exact path="/expenses" component={Expenses} />
            <Route exact path="/loans" component={Loan} />
            {/*<Route exact path="/cash-flows" component={CashFlows} />*/}
            {/*<Route exact path="/cash-dashboard" component={CashDashboard} />*/}
            <Route exact path="/buying" component={Buying} />
            <Route exact path="/selling" component={Selling} />
            <Route exact path="/cash-book" component={CashBook} />
            <Route exact path="/heat-treatment-oven" component={HeatTreatmentOven} />
            {/*<Route exact path="/bank" component={Bank} />*/}
            <Route exact path="/profile" component={Profile} />
            {is_cookie_set && (
                <>
                  <Route exact path="/rough/:code" component={Rough} />
                  <Route exact path="/lots/:code" component={Lots} />
                  <Route exact path="/sorted-lots/:code" component={SortedLots} />
                  <Route exact path="/c-p/:code" component={CP} />
                </>
            )}

            {/*<Redirect from="*" to="/dashboard" />*/}
          </Main>
        </Switch>
      </div>
  );
}

export default App;
